import React, {useState} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { useDispatch } from 'react-redux';
import { setUser } from './userSlice';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus } from '../../request/Auth/authSlice';

const Forgot = () => {
    const dispatch = useDispatch();
    const initialLogin = {
        email: '',

    }
    const appMode = useSelector((state) => state.mode.mode);
    const redirectTo = useSelector((state) => state.redirect.redirect);
    const navigate = useNavigate();

    const [error, setErros] = useState('');
    const [registerForm, setRegisterForm] = useState(initialLogin);
    
    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(

        async () => {
          return await apiClient.post(`/api/forgot-password`, {
            email: registerForm.email,
          });
        },
        {
          onSuccess: (res) => {            
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            navigate('/update-password');
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.message) 
            const event2 = new Event('processed');
            window.dispatchEvent(event2);      
          },
        }
      );
      const onSubmit = async() =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      } 

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={8} sx={{display: {xs: 'none', md: 'flex'}}}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height: "100%" }}
                        image='https://res.cloudinary.com/dzxyvdq14/image/upload/v1691381238/bgsearis-1.jpg'
                        alt='login'
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <img  src={appMode === 'dark'? 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1696451784/futura-assets3d.png' : 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1697123710/futuraassets-darklogo.png'} style={{width: '154px', height: '63px', marginBottom: '20px'}} alt="logo" />
                    <Divider />
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        Recover Account
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to recover your account
                    </Typography>
                    
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="email">Enter Email</InputLabel>
                            {error !== '' ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="email"
                            type={'email'}
                            name="email"
                            label="Enter Email"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="email"
                            type={'email'}
                            name="email"
                            label="Enter Email"
                            />
                             }
                                                        
                        </FormControl>

                        <Typography color='error'  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error}
                        </Typography>
                        
                       
                        <Box mt={4} sx={{display: 'flex', justifyContent: 'space-evenly'}}>
                            <Link color="inherit"  to='/login' component={RouterLink} underline="none">
                            Login
                            </Link>
                            <Link  to='/register' component={RouterLink} underline="none" color="inherit">
                            Register
                            </Link>
                        </Box>

                    </CardContent>
                    <CardActions>
                    <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Sending code...': 'Send Verification Code'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default Forgot
