import * as React from 'react';
import { Fragment } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import TapasIcon from '@mui/icons-material/Tapas';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ListIcon from '@mui/icons-material/List';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import CreateIcon from '@mui/icons-material/Create';
export default function Admin() {
  return (
    <Fragment>
         <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Profile`} />
                  </ListItemButton>
              </ListItem>
            </Link>

        {/* <Link  to='/upload' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Upload`} />
            </ListItemButton>
        </ListItem>
        </Link> */}



        <Link  to='/list-users' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <PeopleIcon/>
            </ListItemIcon>
            <ListItemText  primary={`users`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/create-plans' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <CreateIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Create Plan`} />
            </ListItemButton>
        </ListItem>
        </Link>

        

        <Link  to='/create-address' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <CreateIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Create Wallets`} />
            </ListItemButton>
        </ListItem>
        </Link>
        <Link  to='/list-fundings' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`List Deposits`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-investments' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`List Investments`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-cards' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`List Cards`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-shareholders' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`List Shareholders`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-loans' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`List Loans`} />
            </ListItemButton>
        </ListItem>
        </Link>

        

        <Link  to='/list-plans' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Investment Plans`} />
            </ListItemButton>
        </ListItem>
        </Link>
        

        

        <Link  to='/list-address' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`List Payment Wallets`} />
            </ListItemButton>
        </ListItem>
        </Link>

        
        <Link  to='/depay-transactions' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Depay Transactions`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/coinremitter-transactions' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <ListIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Coinremitter Transactions`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-withdrawals' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <MoneyOffIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Withdrawals`} />
            </ListItemButton>
        </ListItem>
        </Link>
        
        
        

        <Link  to='/send-mail' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <EmailIcon/>
            </ListItemIcon>
            <ListItemText  primary={`send email`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/send-sms' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <SmsIcon/>
            </ListItemIcon>
            <ListItemText  primary={`send sms`} />
            </ListItemButton>
        </ListItem>
        </Link>

        


        
        {/* <Link  to='/admin-analysis' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Analysis`} />
            </ListItemButton>
        </ListItem>
        </Link> */}

        

        {/* <Link  to='/push-notification' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Sub notification`} />
            </ListItemButton>
        </ListItem>
        </Link> */}

       


    </Fragment>
  );
}